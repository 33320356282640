import mainjson from './main';
export default function GetData() {
        return {
        "apitype":"questions",
        "id":"game_question_id",
        "subid":"game_question_game_id",
        "options":["edit","insert","delete"],
        "nameSingle":"question",
        "nameMultiple":"questions",
        "subidname":"game_question_text",
        "order":"game_question_order",
        "orderArrows":true,
        "extraoptions":[
            {
                "name":"Options",
                "page":"/textgameoptions",
            }
        ],
        "filters":
            [
               
            ]
        ,
        "fields":{
            "field1":{
                "name":"Question Text",
                "field":"game_question_text",
                "type":"TextInput",
                "required":false,
                "list":true,
            },
            "field2":{
                "name":"Sound (OGG)",
                "field":"game_question_audio",
                "type":"SoundUpload",
                "required":false,
                "list":true,
            },
            "field3":{
                "name":"Sound (OGG)",
                "field":"game_question_audio",
                "type":"SoundUpload",
                "required":false,
                "list":true,
            }
        }
    }
    
}